<template>
  <v-autocomplete
    v-model="selected"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    return-object
    label="Código / Nome"
    outlined
    rounded
    dense
    @input="onSelect"
    @update:search-input="searchItems"
    @focus.once="searchItems"
  >
    <template v-slot:no-data>
      <div class="pa-2">
        <h6 class="text-subtitle-2 text-center">Nenhuma produto escontrado</h6>
      </div>
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  props: {
    value: {
      type: [Object, Number],
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: { id: null, text: null },
      search: null,
      isLoading: false,
      focused: false,
      items: [],
    }
  },
  methods: {
    onSelect() {
      if (!this.selected) {
        this.selected = { id: null, text: null }
      }

      if (this.returnObject) {
        this.$emit('input', this.selected)
        return
      }
      this.$emit('input', this.selected.id)
    },
    async getProducts() {
      if (this.search !== null && this.search === this.selected.text) {
        return
      }
      try {
        this.isLoading = true
        const response = await this.$api.get('/search/products', {
          params: {
            textsearch: this.search,
          },
        })
        console.log(response)
        this.items = response.data.map((product) => ({
          id: product.id,
          text: `${product.product_reference} - ${product.product_name}`,
        }))
      } finally {
        this.isLoading = false
      }
    },
    searchItems: debounce(function () {
      this.getProducts()
    }, 500),
  },
}
</script>
